import $ from "jquery"

$(function(){
  $("body").on("click",'[data-controller="storage-stats"]', function(event) {
    event.preventDefault();
    $.ajax({
      url: event.target.parentElement.parentElement.dataset.url,
      success: function(response){
        $('#modal-body').html(response);
        $('#statistics_modal').modal('show');
      }
    })
  });
});
